import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import NavbardDashboard from "../components/dashboard/NavbarDashboard";
import SidebarDashboard from "../components/dashboard/SidebarDashboard";
import useAxios from "../utils/useAxios";
import "../assets/css/Form.css";
import "../assets/scss/customer.scss";

const CustomerSet = () => {
  const api = useAxios();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const initialFormData = Object.freeze({
    nama_customer: "",
    kecamatan: "",
    kabupaten: "",
    alamat: "",
    latitude: "",
    longitude: "",
    nama_pemilik: "",
    telepon: "",
    ada_whatsapp: "",
    tanggal_lahir: "",
    tipe_outlet: "",
    jumlah_pegawai: "",
    fasilitas: "",
    supplier: "",
    rute: "",
    tipe_pembelian: "",
    nama_salesman: "",
    status_program: "",
    ktp: "",
    npwp: "",
    mforce: "",
    nama_admin: "",
    jenis: "",
    ktpnpwp: "",
    produk: "",
    key: "",
    target: "",
    divisi: "",
  });

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [postData, updateFormData] = useState(initialFormData);
  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const getvalue = (e) => {
    setValueSelect(e.value);
    setLabelSelect(e.label);
  };

  const [valueselect, setValueSelect] = useState("");
  const [labelselect, setLabelSelect] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = { theme: "default", id: 1 };
    const response = await api.post("/gelora/theme/update/", body, config);
    if (response.status === 201) {
      navigate("/dashboard");
    } else {
      alert("Setting gagal, mohon periksa kembali koneksi anda");
    }
  };

  const handleChange = (e) => {
    if ([e.target.name]) {
      updateFormData({
        ...postData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getCustomer = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await api.get("/gelora/customer/tambah/", config);
    if (response.status === 200) {
      if (response.data != "") {
        setCustomer(response.data);
      } else {
        setCustomer(null);
      }
    } else {
      setCustomer(null);
    }
  };

  const [selected, setSelected] = React.useState("");

  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };

  const cilacap = ["Adipala", "Bantarsari", "Cimanggu"];
  const banyumas = ["Ajibarang", "Cilongok", "Kalibagor"];
  const boyolali = ["Juwangi", "Kemusu", "Musuk"];

  let type = null;

  let options = null;

  if (selected === "Cilacap") {
    type = cilacap;
  } else if (selected === "Banyumas") {
    type = banyumas;
  } else if (selected === "Boyolali") {
    type = boyolali;
  }

  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }

  const [kabupaten, setKabupaten] = useState(null);

  const getKabupaten = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await api.get("/gelora/kabupatenlist/", config);
    console.log(response.data);
    if (response.status === 200) {
      if (response.data != "") {
        setKabupaten(response.data);
      } else {
        setKabupaten(null);
      }
    } else {
      setKabupaten(null);
    }
  };

  useEffect(() => {
    getKabupaten();
  }, []);
  let no = 1;

  return (
    <div className="dashboard">
      <NavbardDashboard sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <main>
        <div className="main__container">
          <Container>
            <h1 className="jmb__heading">Data Customer</h1>
            <form>
              {/* {
                                        kabupaten && kabupaten?.map((item) => (
                                        <tr key={item?.id}>
                                            <td >{no++}</td>
                                            <td>{item?.nama_kabupaten}</td>
                                        </tr>
                                        ))
                                    } */}
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="nama_customer"
                  onChange={handleChange}
                  placeholder="Nama Customer"
                  value={postData.nama_customer}
                  required
                />
              </div>
              {/* <div>
                                        {
                                            kabupaten && kabupaten?.map((item) => (
                                            <select onChange={changeSelectOptionHandler} className='use' name='kabupaten'>
                                                <option>- Kabupaten -</option>
                                                <option key={item?.id}>{item?.nama_kabupaten}</option>
                                            </select>
                                            ))
                                        }
                                    </div> */}
              <div>
                <select
                  onChange={changeSelectOptionHandler}
                  className="use"
                  name="kabupaten"
                >
                  <option>- Kabupaten -</option>
                  <option>Cilacap</option>
                  <option>Banyumas</option>
                  <option>Boyolali</option>
                </select>
              </div>
              <div>
                <select class="use" name="kecamatan">
                  <option>- Kecamatan -</option>
                  {options}
                </select>
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="alamat"
                  onChange={handleChange}
                  placeholder="Alamat"
                  value={postData.alamat}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="latitude"
                  onChange={handleChange}
                  placeholder="Latitude"
                  value={postData.latitude}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="longitude"
                  onChange={handleChange}
                  placeholder="Longitude"
                  value={postData.longitude}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="nama_pemilik"
                  onChange={handleChange}
                  placeholder="Nama Pemilik"
                  value={postData.nama_pemilik}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="telepon"
                  onChange={handleChange}
                  placeholder="Telepon"
                  value={postData.telepon}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="ada_whatsapp"
                  onChange={handleChange}
                  placeholder="Whatsapp"
                  value={postData.ada_whatsapp}
                  required
                />
              </div>
              <label className="lab">Tanggal Lahir</label>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="date"
                  name="tanggal_lahir"
                  onChange={handleChange}
                  placeholder="Tanggal Lahir"
                  value={postData.tanggal_lahir}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="tipe_outlet"
                  onChange={handleChange}
                  placeholder="Tipe Outlet"
                  value={postData.tipe_outlet}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="jumlah_pegawai"
                  onChange={handleChange}
                  placeholder="Jumlah Pegawai"
                  value={postData.jumlah_pegawai}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="fasilitas"
                  onChange={handleChange}
                  placeholder="Fasilitas"
                  value={postData.fasilitas}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="supplier"
                  onChange={handleChange}
                  placeholder="Supplier"
                  value={postData.supplier}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="rute"
                  onChange={handleChange}
                  placeholder="Rute"
                  value={postData.rute}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="tipe_pembelian"
                  onChange={handleChange}
                  placeholder="Tipe Pembelian"
                  value={postData.tipe_pembelian}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="nama_salesman"
                  onChange={handleChange}
                  placeholder="Nama Salesman"
                  value={postData.nama_salesman}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="status_program"
                  onChange={handleChange}
                  placeholder="Status Program"
                  value={postData.status_program}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="ktp"
                  onChange={handleChange}
                  placeholder="KTP"
                  value={postData.ktp}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="npwp"
                  onChange={handleChange}
                  placeholder="NPWP"
                  value={postData.npwp}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="mforce"
                  onChange={handleChange}
                  placeholder="Mforce"
                  value={postData.mforce}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="nama_admin"
                  onChange={handleChange}
                  placeholder="Nama Admin"
                  value={postData.nama_admin}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="jenis"
                  onChange={handleChange}
                  placeholder="Jenis"
                  value={postData.jenis}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="ktpnpwp"
                  onChange={handleChange}
                  placeholder="KTP NPWP"
                  value={postData.ktpnpwp}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="text"
                  name="produk"
                  onChange={handleChange}
                  placeholder="Produk"
                  value={postData.produk}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="key"
                  onChange={handleChange}
                  placeholder="Key"
                  value={postData.key}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="target"
                  onChange={handleChange}
                  placeholder="Target"
                  value={postData.target}
                  required
                />
              </div>
              <div className="form-inputs">
                <input
                  className="form-input"
                  type="number"
                  name="divisi"
                  onChange={handleChange}
                  placeholder="Divisi"
                  value={postData.divisi}
                  required
                />
              </div>
              <button
                className="form-input-btn"
                id="simpan"
                onClick={handleSubmit}
              >
                Save Data
              </button>
            </form>
          </Container>
        </div>
      </main>
      <SidebarDashboard sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );
};

export default CustomerSet;
