import "../../assets/css/dashboard/MainDashboard.css";
import React, { useContext, useEffect, useState } from 'react'
import AuthContext from "../../context/AuthContext"
import useAxios from '../../utils/useAxios'

const Main = () => {
    const [message, setMessage] = useState([])
    const { user } = useContext(AuthContext)

    const api = useAxios()

    return (
        <main>
            <div className="main__container">
                <div className="main__title">
                    <div className="main_greeting">
                        {user && <h1>Hallo, {user?.username}</h1>}
                        <p>Selamat Datang pada Dash Admin</p>
                    </div>
                </div>

                <div className="main__cards">
                    <div className="card">
                        <i className="fa fa-user-o fa-2x lightblue"></i>
                        <div className="card_inner">
                            <p className="text-primary">Total Admin</p>
                            <span className="font-bold text-title">2</span>
                        </div>
                    </div>

                    <div className="card">
                        <i className="fa fa-users fa-2x yellow"></i>
                        <div className="card_inner">
                            <p className="text-primary">Total Pengunjung</p>
                            <span className="font-bold text-title">100</span>
                        </div>
                    </div>

                    <div className="card">
                        <i className="fa fa-podcast fa-2x purple"></i>
                        <div className="card_inner">
                            <p className="text-primary">Total Tampilan</p>
                            <span className="font-bold text-title">6</span>
                        </div>
                    </div>

                    <div className="card">
                        <i className="fa fa-vcard-o fa-2x green"></i>
                        <div className="card_inner">
                            <p className="text-primary">Total Email User</p>
                            <span className="font-bold text-title">80</span>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Main;
