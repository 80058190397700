import "../../assets/css/Register.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useContext } from 'react';
import useAxios from '../../utils/useAxios'
import { Navigate } from "react-router-dom";
import axios from "axios";
import Footer from "../../components/Footer";
const Register = () => {

  const [accountCreated, setAccountCreated] = useState(false);
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    username: '',
    divisi: '',
    password: '',
    re_password: ''
  });
  const api = useAxios()
  const { fullname, email, username, divisi, password, re_password } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password === re_password) {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      const body = { 'fullname': fullname, 'email': email, 'username': username, 'divisi': divisi, 'password': password }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/accounts/register/`, body, config)
      if (response.status === 201) {
        setAccountCreated(true)
      } else {
        alert('Register gagal, email dan username harus unik')
      }

    } else {
      alert('Register gagal password tidak sama!')
    }
  };

  if (accountCreated) {
    return <Navigate to='/login' />
  }

  return (
    <div>
      <div className="main-regis">
        <div className="sub-main">
          <div>
            <div className="imgs">
              <div className="container-image">
                <FontAwesomeIcon icon={faUserPlus} className="fw" />
              </div>
            </div>
            <br />
            <h1>Register Page</h1>
            <br />
            <div>
              <form onSubmit={e => onSubmit(e)}>
                <div>
                  <input className="box__input" type="text" placeholder="Nama" id="fullname" name="fullname" onChange={e => onChange(e)} />
                </div>
                <div className="second-input">
                  <input className="box__input" type="email" placeholder="Email" id="email" name="email" onChange={e => onChange(e)} />
                </div>
                <div className="third-input">
                  <input className="box__input" type="text" placeholder="Username" id="username" name="username" onChange={e => onChange(e)} />
                </div>
                <div className="third-input">
                  <input className="box__input" type="text" placeholder="Divisi" id="divisi" name="divisi" onChange={e => onChange(e)} />
                </div>
                <div className="fourth-input">
                  <input className="password box__input" type="password" placeholder="Password" id="password" name="password" onChange={e => onChange(e)} />
                </div>
                <div className="fifth-input">
                  <input className="password box__input" type="password" placeholder="Confirm Password" id="re_password" name="re_password" onChange={e => onChange(e)} />
                </div>
                <div className="regis-button">
                  <button className="regis-btn" type="submit">Register</button>
                </div>
                <div className="back">
                  <a href="/login" className="back__home">Back to Login</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Register;
