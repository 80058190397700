import React, { useContext, useEffect, useState } from "react";
import "../assets/scss/home.scss";

function Home() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };

  // Toogle Menu
  const [show, setShow] = useState(true);

  return (
    <div className={themecss}>
      <div className="bg-light p-5 rounded-lg m-3 home" id="Home">
        <div className="container">
          <div className="home__content">
            <div id="home" className="home__meta">
              <a className="lokertheme" href="#About">
                About Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
