import React, { useContext, useEffect, useState } from "react";
import "../assets/scss/footer.scss";
import Logo from "../assets/img/logo.png";

function Footer() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);
  return (
    <div className={themecss}>
      <div
        className="footer d__flex align__items__center justify__content__space__between pz-10"
        style={{ padding: "20px 20px", zIndex: "100" }}
      >
        <div className="row__cont">
          <div className="foo_1">
            <div className="contact__box">
              <div className="contact__meta">
                <img src={Logo} alt="" className="foo__img" />
                <p className="foo__text white">
                  ©2022 Gelora Corp All Rights Reserved
                </p>
              </div>
            </div>
          </div>

          <div className="foo_2">
            <div className="contact__box">
              <div className="contact__meta">
                <h3 className="foo__text white">CONTACT ME</h3>
                <p className="hire__text white">Instagram</p>
                <p className="hire__text white">Twitter</p>
                <p className="hire__text white">Facebook</p>
                <p className="hire__text white">LinkedIn</p>
              </div>
            </div>
          </div>

          <div className="foo_3">
            <div className="contact__box">
              <div className="contact__meta">
                <h3 className="foo__text white">VISIT OUR HEADQUARTER</h3>
                <p className="hire__text white">
                  Jl RE Martadinata no 83 Cilacap, Jawa Tengah, 53213 Indonesia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
