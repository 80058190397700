import "../../assets/css/Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons"
import AuthContext from '../../context/AuthContext'
import React, { useContext } from "react";
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer.js'

const Login = () => {
  const { loginUser } = useContext(AuthContext)

  return (
    <div>
      <div className="main-lgn">
        <div className="sub-main-lgn">
          <div>
            <div className="imgs">
              <div className="container-image">
                <FontAwesomeIcon icon={faUser} className="fw" />
              </div>
            </div>
            <br />
            <h1>Login Page</h1>
            <br />

            <div>
              <form onSubmit={loginUser}>
                <div>
                  <input
                    className="lgn-input"
                    type="text"
                    placeholder="Username"
                    id="username"
                    name="username"
                  />
                </div>
                <div className="second-input">
                  <input
                    className="lgn-input"
                    type="password"
                    placeholder="Password"
                    id="password"
                    name="password"
                  />
                </div>
                <div className="login-button">
                  <button className="login-btn" type="submit">
                    Login
                  </button>
                </div>
                <div className="back" >
                  <Link to={"/"} className="back__home">Back</Link>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
}

export default Login;
