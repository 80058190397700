import "../../assets/css/dashboard/NavbarDashboard.css"
import avatar from "../../assets/img/avatar.png";

const Navbar = ({ sidebarOpen, openSidebar }) => {

    return (
        <nav className="navbar-dashboard">
            <div className="nav_icon" onClick={() => openSidebar()}>
            <i className="fa fa-bars"></i>
            </div>
            <div className="navbar__left">
            </div>
            <div className="navbar__right">
                <a href="#">
                    <img width="30" src={avatar} alt="avatar" />
                </a>
            </div>
        </nav>

    );
};

export default Navbar;
