import Pelumasa from "../../assets/img/company/pelumas/pelumas-a.png";
import Pelumasb from "../../assets/img/company/pelumas/pelumas-b.png";
import Pelumasc from "../../assets/img/company/pelumas/pelumas-c.png";
import Footer from "../../components/Footer.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

function PelumasCompany() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);
  const [pelumas, SetPelumas] = useState(true);
  const [otomotif, SetOtomotif] = useState(false);
  const [industri, SetIndustri] = useState(false);
  const [marine, SetMarine] = useState(false);

  const TampilPelumas = () => {
    SetPelumas(true);
    SetOtomotif(false);
    SetIndustri(false);
    SetMarine(false);
  };
  const TampilOtomotif = () => {
    SetPelumas(false);
    SetOtomotif(true);
    SetIndustri(false);
    SetMarine(false);
  };
  const TampilIndustri = () => {
    SetPelumas(false);
    SetOtomotif(false);
    SetIndustri(true);
    SetMarine(false);
  };
  const TampilMarine = () => {
    SetPelumas(false);
    SetOtomotif(false);
    SetIndustri(false);
    SetMarine(true);
  };

  return (
    <div className={themecss}>
      <a>
        <Link to={"/"}>
          <button className="backhome">
            <FontAwesomeIcon icon={faBackward} /> Back
          </button>
        </Link>
      </a>
      <div className="pelumas component__space" id="Pelumas">
        <div className="heading">
          <h1 className="heading">Lubricants Division</h1>
          <p className="heading p__color">
            We provide lubricants for industrial and commercial sectors in
            Indonesia. Our products vary from hydraulics, gears, turbine, grease
            all the way to motorcycle engine oil lube. We can provide lubricants
            in tank, bulk, drum and even bottle.
          </p>
        </div>
        {pelumas === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="project__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Pelumas Otomotif</h1>

                    {/* <button className="btn__cek" onClick={TampilOtomotif}>Cek Produk</button> */}
                  </div>
                </div>
              </div>

              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Pelumasb} alt="" className="project__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Pelumas Industri</h1>

                    {/* <button className="btn__cek" onClick={TampilIndustri}>Cek Produk</button> */}
                  </div>
                </div>
              </div>

              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Pelumasc} alt="" className="project__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Pelumas Marine</h1>

                    {/* <button className="btn__cek" onClick={TampilMarine}>Cek Produk</button> */}
                  </div>
                </div>
              </div>
            </div>
            <button className="kunjungi_web" onClick={TampilMarine}>
              Kunjungi Website
            </button>
          </div>
        ) : null}
        {otomotif === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__meta">
                    <h1 className="produk__pelumas__text">Tabung Gas</h1>
                  </div>
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="pelumas__img" />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn_stuck pointer" onClick={TampilPelumas}>
              <FontAwesomeIcon icon={faBackward} /> LPG Category
            </button>
          </div>
        ) : null}
        {industri === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__meta">
                    <h1 className="produk__pelumas__text">Tabung Gas</h1>
                  </div>
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="pelumas__img" />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn_stuck pointer" onClick={TampilPelumas}>
              <FontAwesomeIcon icon={faBackward} /> LPG Category
            </button>
          </div>
        ) : null}

        {marine === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__meta">
                    <h1 className="produk__pelumas__text">Tabung Gas</h1>
                  </div>
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="pelumas__img" />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn_stuck pointer" onClick={TampilPelumas}>
              <FontAwesomeIcon icon={faBackward} /> LPG Category
            </button>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

export default PelumasCompany;
