import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/scss/company.scss";
import Fuel from "../assets/img/division/1.png";
import Automotive from "../assets/img/division/2.png";
import Gas from "../assets/img/division/3.png";
import Logistic from "../assets/img/division/4.png";
import Hospitality from "../assets/img/division/5.png";
import Station from "../assets/img/division/6.png";

function Division() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();

    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };

  useEffect(() => {
    cektheme();
  }, []);

  return (
    <div className={themecss}>
      <div className="company component__space" id="Portfolio">
        <div className="heading">
          <h1 className="headingcompany">Our Division</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="coll__3">
              <div className="project__box pointer relative">
                <div className="project__box__img pointer relative">
                  <div className="project__img__box">
                    <img src={Fuel} alt="" className="project__img" />
                  </div>
                  <div className="mask__effect"></div>
                </div>
                <div className="project__meta absolute"></div>
              </div>
              <Link to="/retail" className="project__btn">
                Fuel Distribution
              </Link>
              <p className="project__text">
                We provide fuel products as agent of Pertamina for industrial
                needs
              </p>
            </div>

            <div className="coll__3">
              <div className="project__box pointer relative">
                <div className="project__box__img pointer relative">
                  <div className="project__img__box">
                    <img src={Automotive} alt="" className="project__img" />
                  </div>
                  <div className="mask__effect"></div>
                </div>
                <div className="project__meta absolute"></div>
              </div>
              <Link to="/retail" className="project__btn">
                Automotive & Lubricants Distribution
              </Link>
              <p className="project__text">
                We distribute lubricants oil for the commercial and industrial
                sector{" "}
              </p>
            </div>

            <div className="coll__3">
              <div className="project__box pointer relative">
                <div className="project__box__img pointer relative">
                  <div className="project__img__box">
                    <img src={Gas} alt="" className="project__img" />
                  </div>
                  <div className="mask__effect"></div>
                </div>
                <div className="project__meta absolute"></div>
              </div>
              <Link to="/retail" className="project__btn">
                Gas & Customer Goods Distribution
              </Link>
              <p className="project__text">
                We served LPG NPSO, gallon of water and customer good products
                direct to customers{" "}
              </p>
            </div>

            <div className="coll__3">
              <div className="project__box pointer relative">
                <div className="project__box__img pointer relative">
                  <div className="project__img__box">
                    <img src={Logistic} alt="" className="project__img" />
                  </div>
                  <div className="mask__effect"></div>
                </div>
                <div className="project__meta absolute"></div>
              </div>
              <Link to="/retail" className="project__btn">
                In Land Logistic Provider
              </Link>
              <p className="project__text">
                We have wingbox, CDD and tank truck available for your logistic{" "}
              </p>
            </div>

            <div className="coll__3">
              <div className="project__box pointer relative">
                <div className="project__box__img pointer relative">
                  <div className="project__img__box">
                    <img src={Hospitality} alt="" className="project__img" />
                  </div>
                  <div className="mask__effect"></div>
                </div>
                <div className="project__meta absolute"></div>
              </div>
              <Link to="/retail" className="project__btn">
                Hospitality Management
              </Link>
              <p className="project__text">
                Partnering with Archipellago International, we operate Hotels
                for you to stay{" "}
              </p>
            </div>

            <div className="coll__3">
              <div className="project__box pointer relative">
                <div className="project__box__img pointer relative">
                  <div className="project__img__box">
                    <img src={Station} alt="" className="project__img" />
                  </div>
                  <div className="mask__effect"></div>
                </div>
                <div className="project__meta absolute"></div>
              </div>
              <Link to="/retail" className="project__btn">
                Fuel Station Management
              </Link>
              <p className="project__text">
                Visit our gas station and enjoy our services and high quality
                fuels
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Division;
