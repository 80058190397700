import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faBackward } from "@fortawesome/free-solid-svg-icons";
import Fave from "../../assets/img/company/hotel/Fave.png";
import CDL from "../../assets/img/company/hotel/CDL.png";
import Footer from "../../components/Footer.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function HotelCompany() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);
  const [hotel, SetHotel] = useState(true);
  const [fave, SetFave] = useState(false);
  const [casa, SetCasa] = useState(false);
  const TampilFave = () => {
    SetHotel(false);
    SetFave(true);
    SetCasa(false);
  };
  const TampilCasa = () => {
    SetHotel(false);
    SetCasa(true);
    SetFave(false);
  };

  const HotelCategory = () => {
    SetHotel(true);
    SetCasa(false);
    SetFave(false);
  };

  return (
    <div className={themecss}>
      <Link to={"/"}>
        <button className="backhome">
          <FontAwesomeIcon icon={faBackward} /> Back
        </button>
      </Link>

      <div className="pelumas component__space" id="Pelumas">
        <div className="heading">
          <h1 className="heading">Hotel Division</h1>
          <p className="heading p__color">
            Working with Archipelago International, we built Aston and Fave in
            Cilacap for your stay. We have ballroom and multiple meeting rooms
            ready for any events in Cilacap.
          </p>
        </div>
        {hotel === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Fave} alt="" className="project__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Fave Hotel Cilacap</h1>
                    <p className="p pelumas__text p__color">
                      Hotel Bintang{" "}
                      <FontAwesomeIcon icon={faStar} className="bintang" />
                      <FontAwesomeIcon icon={faStar} className="bintang" />
                      <FontAwesomeIcon icon={faStar} className="bintang" />
                    </p>

                    <button className="btn__cek" onClick={TampilFave}>
                      Cek Deskripsi
                    </button>
                  </div>
                </div>
              </div>

              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={CDL} alt="" className="project__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Casa De Lani Baturaden</h1>
                    <p className="p pelumas__text p__color">
                      Hotel Bintang{" "}
                      <FontAwesomeIcon icon={faStar} className="bintang" />
                    </p>

                    <button className="btn__cek" onClick={TampilCasa}>
                      Cek Deskripsi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {casa === true ? (
          <div className="container">
            <div className="row">
              <div className="col__2">
                <img src={CDL} alt="" className="keterangan__img" />
              </div>
              <div className="col__2">
                <div className="pelumas__meta">
                  <h1 className="keterangan__pelumas__text">
                    Casa De Lani Baturaden
                  </h1>
                  <p className="p keterangan__pelumas__text p__color">
                    Gelora Group (A World Class Company) adalah sebuah
                    perusahaan besar di Indonesia yang dipimpin oleh Direktur
                    Utama Ibu Laniati Dewi. Grup ini memulai usaha pada bidang
                    Migas, kemudian terus berkembang dan merambah ke berbagai
                    bidang seperti perhotelan, resort, restaurant dll. Beberapa
                    contoh perusahaan yang bergerak di berbagai bidang bisnis
                    seperti layanan distribusi minyak dan gas, SPBU Pertamina,
                    layanan mekanik dan aksesoris mobil, hotel, restoran, ritel,
                    pelumas sepeda motor dan mesin industri dari PT. Pertamina.
                  </p>

                  <button className="btn_stuck pointer" onClick={HotelCategory}>
                    <FontAwesomeIcon icon={faBackward} /> Hotel Category
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {fave === true ? (
          <div className="container">
            <div className="row">
              <div className="col__2">
                <img src={Fave} alt="" className="keterangan__img" />
              </div>
              <div className="col__2">
                <div className="pelumas__meta">
                  <h1 className="keterangan__pelumas__text">Fave Hotel</h1>
                  <p className="p keterangan__pelumas__text p__color">
                    Gelora Group (A World Class Company) adalah sebuah
                    perusahaan besar di Indonesia yang dipimpin oleh Direktur
                    Utama Ibu Laniati Dewi. Grup ini memulai usaha pada bidang
                    Migas, kemudian terus berkembang dan merambah ke berbagai
                    bidang seperti perhotelan, resort, restaurant dll. Beberapa
                    contoh perusahaan yang bergerak di berbagai bidang bisnis
                    seperti layanan distribusi minyak dan gas, SPBU Pertamina,
                    layanan mekanik dan aksesoris mobil, hotel, restoran, ritel,
                    pelumas sepeda motor dan mesin industri dari PT. Pertamina.
                  </p>

                  <button className="btn_stuck pointer" onClick={HotelCategory}>
                    <FontAwesomeIcon icon={faBackward} /> Hotel Category
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

export default HotelCompany;
