import React, { useContext, useState, useEffect, useRef } from "react";
import { Container, Table, Button } from "reactstrap";
import { useNavigate } from "react-router-dom"
import NavbardDashboard from "../components/dashboard/NavbarDashboard"
import SidebarDashboard from "../components/dashboard/SidebarDashboard"
import useAxios from '../utils/useAxios'
import '../assets/scss/jumbotron.scss'
import '../assets/css/Form.css'
import Select from 'react-select'
import theme1 from '../assets/img/loker/theme1.png'
import theme2 from '../assets/img/loker/theme2.png'
import theme3 from '../assets/img/loker/theme3.png'
import theme4 from '../assets/img/loker/theme4.png'
import theme5 from '../assets/img/loker/theme5.png'
import theme6 from '../assets/img/loker/theme6.png'
import { exportComponentAsPNG } from "react-component-export-image"


const JumbotronSet = () => {
  const componentRef = useRef()
  const [posisiList, setposisiList] = useState([{ posisi: "" }]);

  const handleChangePosisi = (e, index) => {
    const { name, value } = e.target;
    const list = [...posisiList];
    list[index][name] = value;
    setposisiList(list);
  };

  const handlePosisiRemove = (index) => {
    const list = [...posisiList];
    list.splice(index, 1);
    setposisiList(list);
  };

  const handlePosisiAdd = () => {
    setposisiList([...posisiList, { posisi: "" }]);
  };

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const [valueselect, setValueSelect] = useState('')
  const [labelselect, setLabelSelect] = useState('')
  const [widthselect, setWidthSelect] = useState('')
  const [cssthemeset, setCssThema] = useState('')
  const [heightselect, setHeightSelect] = useState('')
  const [formData, setFormData] = useState({
    informasi: '',
    catatan: ''
  });
  const api = useAxios()
  const { informasi, catatan } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const dataposisi = posisiList.map(({ posisi }) => `${posisi}`).join(', ');
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
    }
    const body = { 'judul': dataposisi, 'informasi': informasi, 'catatan': catatan }
    const response = await api.post('/gelora/loker/', body, config)
    if (response.status === 201) {
      exportComponentAsPNG(componentRef, {
        html2CanvasOptions: {
          width: widthselect, height: heightselect,
          onclone: (clonedDoc) => {
            clonedDoc.getElementById("dataloker").style.visibility = "visible";
          }
        }
      })
      navigate('/dashboard')
    } else {
      alert('Input gagal, mohon periksa kembali form anda')
    }
  }

  var optionthema = [
    {
      value: theme1,
      width: 1080,
      height: 1080,
      label: "Theme 1 Ratio 1x1",
      csstheme: "theme1_1x1"
    },
    {
      value: theme2,
      width: 1080,
      height: 1080,
      label: "Theme 2 Ratio 1x1",
      csstheme: "theme2_1x1"
    },
    {
      value: theme3,
      width: 1080,
      height: 1080,
      label: "Theme 3 Ratio 1x1",
      csstheme: "theme3_1x1"
    },
    {
      value: theme4,
      width: 1080,
      height: 1350,
      label: "Theme 1 Ratio 4x5",
      csstheme: "theme4_4x5"
    },
    {
      value: theme5,
      width: 1080,
      height: 1350,
      label: "Theme 2 Ratio 4x5",
      csstheme: "theme5_4x5"
    },
    {
      value: theme6,
      width: 1080,
      height: 1350,
      label: "Theme 3 Ratio 4x5",
      csstheme: "theme6_4x5"
    }

  ]

  const getvalue = (e) => {
    setValueSelect(e.value)
    setLabelSelect(e.label)
    setWidthSelect(e.width)
    setHeightSelect(e.height)
    setCssThema(e.csstheme)
  }

  const TampilLoker = React.forwardRef((props, ref) => {

    let no = 1

    return (
      <div ref={ref}>
        <div id="dataloker" style={{
          backgroundImage: `url(${valueselect})`, width: widthselect, height: heightselect, backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
          , visibility: 'hidden'

        }} >
          <div className={cssthemeset}>

            <div className="output">
              {posisiList &&
                posisiList.map((singlePosisi, index) => (
                  <h1 className="posisi_loker" key={index}>{posisiList.length > 1 ? `${no++}. ` : null}{singlePosisi.posisi}</h1>
                ))}
            </div>
            <h1 className="kualifikasi">Kualifikasi</h1>
            <div className="output-kualifikasi">
              {informasi !== '' ? <p className="isi_kualifikasi" style={{ whiteSpace: "pre-line" }}>{informasi}</p> : <p className="isi_kualifikasi">Kualifikasinya adalah</p>}
            </div>
          </div>

        </div>
      </div>
    );
  })

  return (
    <div className="dashboard">

      <NavbardDashboard sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <main>

        <div className="main__container">
          <Container>
            <h1 className="jmb__heading">Jumbotron Setting (Loker)</h1>
            <form onSubmit={e => onSubmit(e)}>
              <div className='form-inputs'>

                <Select placeholder="Select Template" options={optionthema} onChange={(e) => getvalue(e)} formatOptionLabel={option => (
                  <div>
                    <img src={option.value} width={60} height={60} />
                    <span>{option.label}</span>
                  </div>
                )} />
              </div>
              <div className='form-inputs'>
                <div className="dynamic">
                  <div className="form-field">
                    {posisiList.map((singlePosisi, index) => (

                      <div key={index} className="posisi-list">
                        <div className="first-division">
                          <input
                            name="posisi"
                            type="text"
                            id="posisi"
                            value={singlePosisi.posisi}
                            onChange={(e) => handleChangePosisi(e, index)}
                            className="form-input"
                            placeholder="Posisi"
                            required
                          />
                          {posisiList.length - 1 === index && posisiList.length < 6 && (
                            <button
                              type="button"
                              className='button-add'
                              onClick={handlePosisiAdd}
                              id="btn_tmb"
                            >Tambah Posisi
                            </button>
                          )}
                        </div>

                        <div className="second-division">
                          {posisiList.length !== 1 && (
                            <button
                              type="button"
                              className="button-remove"
                              id="btn_remove"
                              onClick={() => handlePosisiRemove(index)}
                            >
                              <span>Remove</span>
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className='form-inputs'>
                <textarea
                  className='form-input-textarea'
                  name='informasi'
                  rows={10}
                  cols={98}
                  onChange={e => onChange(e)}
                  id="kualifikasi"
                  placeholder='Kualifikasi'
                />
              </div>

              {/* <div className='form-inputs'>
                <input
                  className='form-input'
                  type='text'
                  name='catatan'
                  onChange={e => onChange(e)}
                  placeholder='Catatan'
                />
              </div> */}
              <button className='form-input-btn' type="submit" id="download">
                Save Pamflet
              </button>
            </form>

          </Container>
          <Container>

            <TampilLoker ref={componentRef} />

          </Container>
        </div>
      </main>
      <SidebarDashboard sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );

}
export default JumbotronSet
