import logo from "../../assets/img/logo.png";
import React, { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import "../../assets/css/dashboard/SidebarDashboard.css";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const { user, logoutUser } = useContext(AuthContext);

  return (
    <div className={sidebarOpen ? "sidebar-responsive" : ""} id="sidebar">
      <div className="sidebar__title">
        <div className="sidebar__img">
          <h1>Dash Admin Gelora</h1>
        </div>
        <i
          className="fa fa-times"
          id="sidebarIcon"
          onClick={() => closeSidebar()}
        ></i>
      </div>

      <div className="sidebar__menu">
        <div className="sidebar__link">
          <i className="fa fa-home"></i>
          <a href="/dashboard" className="sb_text">
            Dashboard
          </a>
        </div>
        <h2>Management</h2>
        <div className="sidebar__link">
          <i className="fa fa-wrench"></i>
          <a href="/dashboard/jumbotron" className="sb_text">
            Jumbrotron Setting
          </a>
        </div>
        <div className="sidebar__link">
          <i className="fa fa-calendar-check-o"></i>
          <a href="/dashboard/theme" className="sb_text">
            Theme Setting
          </a>
        </div>
        <div className="sidebar__link">
          <i className="fa fa-download"></i>
          <a href="/dashboard/customer" className="sb_text">
            Customer Input
          </a>
        </div>
        <div className="sidebar__link">
          <i className="fa fa-upload"></i>
          <a href="/dashboard/customershow" className="sb_text">
            Customer Show
          </a>
        </div>
        <div className="sidebar__logout">
          <i className="fa fa-power-off"></i>
          <a href="#" onClick={logoutUser} className="sb_text">
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
