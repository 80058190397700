import React from "react";
import Navbar from "../components/Navbar";
import Home from "../components/Home";
import About from "../components/About";
import Division from "../components/Division";
import Partners from "../components/Partners";
import Services from "../components/Services";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import "../App.css";

const Beranda = () => {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Division />
      <Contact />
      <Services />
      <Partners />
      <Footer />
    </div>
  );
};
export default Beranda;
