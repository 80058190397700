import Pelumasa from "../../assets/img/company/pelumas/pelumas-a.png";
import Pelumasb from "../../assets/img/company/pelumas/pelumas-b.png";
import "../../assets/scss/allcompany.scss";
import Footer from "../../components/Footer.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

function ElpijiCompany() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);

  const [elpiji, SetElpiji] = useState(true);
  const [gas, SetGas] = useState(false);
  const [npso, SetNpso] = useState(false);
  const TampilElpiji = () => {
    SetElpiji(true);
    SetGas(false);
    SetNpso(false);
  };
  const TampilGas = () => {
    SetElpiji(false);
    SetGas(true);
    SetNpso(false);
  };
  const TampilNPSO = () => {
    SetElpiji(false);
    SetGas(false);
    SetNpso(true);
  };

  return (
    <div className={themecss}>
      <a>
        <Link to={"/"}>
          <button className="backhome">
            <FontAwesomeIcon icon={faBackward} /> Back
          </button>
        </Link>
      </a>
      <div className="pelumas component__space" id="Pelumas">
        <div className="heading">
          <h1 className="heading">LPG (Liquified Petroleum Gas) </h1>
          <p className="heading p__color">
            Division We distributes Pertamina’s non-subsidized LPG. Our products
            are LPG Blue 12Kg, Bright Gas Can, Bright Gas 12Kg, 5.5Kg, Pertamina
            LPG 50 Kg and LPG Bulk.
          </p>
        </div>
        {elpiji === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="pelumas__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Dist 3Kg</h1>
                    <p className="p pelumas__text p__color">
                      Distribusi tabung elpiji 3Kg adalah keagenan resmi
                      Pertamina yang menyalurkan pendistribusian gas 3Kg pada
                      masyarakat.
                    </p>
                    <button className="btn__cek" onClick={TampilGas}>
                      Kunjungi Website
                    </button>
                  </div>
                </div>
              </div>

              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Pelumasb} alt="" className="pelumas__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">Agen NPSO</h1>
                    <p className="p pelumas__text p__color">
                      Jaringan distribusi Pertamina yang melaksanakan kegiatan
                      pemasaran LPG non subsidi kepada konsumen ( Elpiji dan
                      Bright Gas).
                    </p>
                    <button className="btn__cek" onClick={TampilNPSO}>
                      Kunjungi Website
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {gas === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__meta">
                    <h1 className="produk__pelumas__text">Tabung Gas</h1>
                  </div>
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="pelumas__img" />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn_stuck pointer" onClick={TampilElpiji}>
              <FontAwesomeIcon icon={faBackward} /> LPG Category
            </button>
          </div>
        ) : null}
        {npso === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__meta">
                    <h1 className="produk__pelumas__text">Tabung Gas</h1>
                  </div>
                  <div className="pelumas__img__box">
                    <img src={Pelumasa} alt="" className="pelumas__img" />
                  </div>
                </div>
              </div>
            </div>
            <button className="btn_stuck pointer" onClick={TampilElpiji}>
              <FontAwesomeIcon icon={faBackward} /> LPG Category
            </button>
          </div>
        ) : null}
      </div>
      <Footer />
    </div>
  );
}

export default ElpijiCompany;
