import React, { useContext, useEffect, useState } from "react";
import "../assets/scss/contact.scss";
import contactImg from "../assets/img/contact.png";

function Contact() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();

    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);

  return (
    <div className={themecss}>
      <div className="contact component__space" id="Contact">
        <div className="row__cont">
          <div className="col__2">
            <div className="contact__box">
              <div className="contact__meta">
                <h1 className="hire__text white">
                  Want to do business or partner with us? Please contact us here{" "}
                </h1>
              </div>
            </div>
          </div>
          <div className="col__2">
            <div className="contact__box">
              <div className="contact__meta">
                <p className="hire__text white">Email Address</p>
                <h3 className="hire__text white">ayusdipu@gmail.com</h3>
                <br></br>
                <br></br>
                <br></br>
                <p className="hire__text white">Mailing Address</p>
                <h3 className="hire__text white">
                  Jln RE. Martadinata No 83, Cilacap, Jawa Tengah 53213
                </h3>
                <br></br>
                <br></br>
                <br></br>
                <p className="hire__text white">Phone Number</p>
                <h3 className="hire__text white">0282-531120</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
