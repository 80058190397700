import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Beranda from "./pages/Beranda";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import JumbotronSet from './pages/JumbotronSet';
import CustomerSet from './pages/CustomerSet';
import CustomerShow from './pages/CustomerShow';
import ThemeSet from './pages/ThemeSet'
import PrivateRoute from './utils/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import BBMIndustriCompany from './pages/CompanyCategori/BBMIndustriCompany'
import BengkelCompany from './pages/CompanyCategori/BengkelCompany'
import ElpijiCompany from './pages/CompanyCategori/ElpijiCompany'
import HotelCompany from './pages/CompanyCategori/HotelCompany'
import PelumasCompany from './pages/CompanyCategori/PelumasCompany'
import RetailCompany from './pages/CompanyCategori/RetailCompany'
import SPBUCompany from './pages/CompanyCategori/SPBUCompany'
import TransportasiCompany from './pages/CompanyCategori/TransportasiCompany'

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route exact path="/" exact element={<Beranda />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/dashboard/theme" element={<PrivateRoute><ThemeSet /></PrivateRoute>} />
            <Route path="/dashboard/jumbotron" element={<PrivateRoute><JumbotronSet /></PrivateRoute>} />
            <Route path="/dashboard/customer" element={<PrivateRoute><CustomerSet /></PrivateRoute>} />
            <Route path="/dashboard/customershow" element={<PrivateRoute><CustomerShow /></PrivateRoute>} />
            <Route exact path="/bbm" element={<BBMIndustriCompany />} />
            <Route exact path="/bengkel" element={<BengkelCompany />} />
            <Route exact path="/elpiji" element={<ElpijiCompany />} />
            <Route exact path="/hotel" element={<HotelCompany />} />
            <Route exact path="/pelumas" element={<PelumasCompany />} />
            <Route exact path="/retail" element={<RetailCompany />} />
            <Route exact path="/spbu" element={<SPBUCompany />} />
            <Route exact path="/transportasi" element={<TransportasiCompany />} />
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
