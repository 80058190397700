import Wangon from "../../assets/img/company/spbu/Wangon.png";
import Karangbawang from "../../assets/img/company/spbu/Karangbawang.png";
import Tanjung from "../../assets/img/company/spbu/Tanjung.png";
import Karangkandri from "../../assets/img/company/spbu/Karangkandri.png";
import Majenang from "../../assets/img/company/spbu/Majenang.png";
import Sampang from "../../assets/img/company/spbu/Sampang.png";
import Damalang from "../../assets/img/company/spbu/Damalang.png";
import Kalierang from "../../assets/img/company/spbu/Kalierang.png";
import Ngasinan from "../../assets/img/company/spbu/Ngasinan.png";
import Kebumen from "../../assets/img/company/spbu/Kebumen.png";
import Semarang from "../../assets/img/company/spbu/Semarang.png";
import Solo from "../../assets/img/company/spbu/Solo.png";
import Footer from "../../components/Footer.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

function SPBUCompany() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);

  return (
    <div className={themecss}>
      <a>
        <Link to={"/"}>
          <button className="backhome">
            <FontAwesomeIcon icon={faBackward} /> Back
          </button>
        </Link>
      </a>
      <div className="pelumas component__space" id="Pelumas">
        <div className="heading">
          <h1 className="heading">Fuel Station Division</h1>
          <p className="heading p__color">
            We built management team that’s dedicated to manage Fuel Station
            Business. Feel safe to refuel at our station group
          </p>
        </div>

        <div className="container">
          <div className="row">
            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Wangon} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.531.05 Wangon</h1>
                  <p className="p pelumas__text p__color">Wangon</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Karangbawang} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.531.06 Karangbawang</h1>
                  <p className="p pelumas__text p__color">Karangbawang</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Tanjung} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.531.07 Tanjung</h1>
                  <p className="p pelumas__text p__color">Tanjung</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Karangkandri} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.532.01 Karangkandri</h1>
                  <p className="p pelumas__text p__color">Karangkandri</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Majenang} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.532.03 Majenang</h1>
                  <p className="p pelumas__text p__color">Majenang</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Sampang} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.532.04 Sampang</h1>
                  <p className="p pelumas__text p__color">Sampang</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Damalang} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.532.05 Damalang</h1>
                  <p className="p pelumas__text p__color">Damalang</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Kalierang} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.563.01 Kalierang</h1>
                  <p className="p pelumas__text p__color">Kalierang</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Ngasinan} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.563.02 Ngasinan</h1>
                  <p className="p pelumas__text p__color">Ngasinan</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Kebumen} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.543.10 Kebumen</h1>
                  <p className="p pelumas__text p__color">Kebumen</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Semarang} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.502.08 Semarang</h1>
                  <p className="p pelumas__text p__color">Semarang</p>
                </div>
              </div>
            </div>

            <div className="col__3">
              <div className="pelumas__box pointer">
                <div className="pelumas__img__box">
                  <img src={Solo} alt="" className="project__img" />
                </div>
                <div className="pelumas__meta">
                  <h1 className="pelumas__text">SPBU 44.577.11 Solo</h1>
                  <p className="p pelumas__text p__color">Solo</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SPBUCompany;
