import React, { useContext, useState, useEffect } from "react";
import { Container, Table, Button } from "reactstrap";
import { useNavigate } from "react-router-dom"
import NavbardDashboard from "../components/dashboard/NavbarDashboard"
import SidebarDashboard from "../components/dashboard/SidebarDashboard"
import "../assets/css/dashboard/MainDashboard.css"
import '../assets/css/Form.css'
import Select from 'react-select'
import useAxios from '../utils/useAxios'

const ThemeSet = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sessioncss, setSessionCss] = useState(null)
  const [valueselect, setValueSelect] = useState('')
  const [labelselect, setLabelSelect] = useState('')

  useEffect(() => {
    getSessionCss()

  }, [setSessionCss])

  const getSessionCss = async () => {
    const response = await api.get('/gelora/theme/')

    if (response) {
      console.log(response.data.theme)
      setSessionCss(response.data.theme)
    }

  }

  var session = [
    {
      value: 1,
      label: "default"
    },
    {
      value: 1,
      label: "chritmas"
    },
    {
      value: 1,
      label: "eid_mubarak"
    }
  ]
  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  const navigate = useNavigate()

  const api = useAxios()


  const getvalue = e => {
    setValueSelect(e.value)
    setLabelSelect(e.label)
  }
  const submitValue = async (e) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
    }
    const body = { 'theme': labelselect, 'id': valueselect }
    const response = await api.post('/gelora/theme/update/', body, config)
    if (response.status === 200) {
      navigate('/dashboard')
    } else {
      alert('Setting gagal, mohon periksa kembali koneksi anda')
    }
  }

  return (
    <div className={'dashboard ${colorTheme}'}>
      <NavbardDashboard sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <main>
        <div className="main__container">
          <Container>
            <h1>Theme Option</h1>
            <br />

            <div className='form-inputs'>

              <Select options={session} onChange={getvalue} />
            </div>

            <button className='form-input-btn' onClick={submitValue}>
              Set Theme
            </button>
            <br />
            <br />
            {sessioncss ? <h1>Current active theme : {sessioncss}</h1> : <h1>Theme not active</h1>}
          </Container>
        </div>
      </main>
      <SidebarDashboard sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>
  );

}

export default ThemeSet