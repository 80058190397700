import React, { useEffect, useState, useMemo } from "react";
import NavbardDashboard from "../components/dashboard/NavbarDashboard"
import SidebarDashboard from "../components/dashboard/SidebarDashboard"
import { TableHeader, Pagination, Search } from "../components/DataTable";
import useFullPageLoader from "../hooks/useFullPageLoader";
import ExternalInfo from "../components/ExternalInfo";
import AppConfig from "../App.config";
import '../assets/scss/customer.scss'

const CustomerShow = () => {
    const [comments, setComments] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const openSidebar = () => {
      setSidebarOpen(true);
    };

    const closeSidebar = () => {
      setSidebarOpen(false);
    };

    const ITEMS_PER_PAGE = 50;

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Name", field: "name", sortable: true },
        { name: "Email", field: "email", sortable: true },
        { name: "Comment", field: "body", sortable: false }
    ];

    useEffect(() => {
        const getData = () => {
            showLoader();

            fetch("https://jsonplaceholder.typicode.com/comments")
                .then(response => response.json())
                .then(json => {
                    hideLoader();
                    setComments(json);
                    console.log(json);
                });
        };

        getData();
    }, []);
    
    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.email.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);

    return (
        <>
            <div className="dashboard">
                <NavbardDashboard sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
                    <main>
                        <div className="main__container">
                            <h1 className="heading__dmain">Show Products</h1>
                                <div className="row w-100">
                                    <div className="col mb-3 col-12 text-center">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Pagination
                                                    total={totalItems}
                                                    itemsPerPage={ITEMS_PER_PAGE}
                                                    currentPage={currentPage}
                                                    onPageChange={page => setCurrentPage(page)}
                                                />
                                            </div>
                                            <div className="col-md-6 d-flex flex-row-reverse">
                                                <Search
                                                    onSearch={value => {
                                                        setSearch(value);
                                                        setCurrentPage(1);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <table className="tb__partner">
                                            <TableHeader
                                                headers={headers}
                                                onSorting={(field, order) =>
                                                    setSorting({ field, order })
                                                }
                                            />
                                            <tbody>
                                                {commentsData.map(comment => (
                                                    <tr>
                                                        <th scope="row" key={comment.id}>
                                                            {comment.id}
                                                        </th>
                                                        <td>{comment.name}</td>
                                                        <td>{comment.email}</td>
                                                        <td>{comment.body}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                        </div>
                    </main>
                <SidebarDashboard sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
            </div>
        </>
    );
};

export default CustomerShow;
