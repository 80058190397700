import Fave from "../../assets/img/company/hotel/Fave.png";
import "../../assets/scss/allcompany.scss";
import Footer from "../../components/Footer.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

function RetailCompany() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);

  const [retail, SetRetail] = useState(true);
  const TampilProduk = () => {
    SetRetail(false);
  };
  const TampilRetail = () => {
    SetRetail(true);
  };
  return (
    <div className={themecss}>
      <Link to={"/"}>
        <button className="backhome">
          <FontAwesomeIcon icon={faBackward} /> Back
        </button>
      </Link>
      <div className="pelumas component__space" id="Pelumas">
        <div className="heading">
          <h1 className="heading">Retail</h1>
          <p className="heading p__color">Retail Company Data</p>
          <p className="heading p__color">
            The data below is the data of our retail company
          </p>
        </div>
        {retail === true ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Fave} alt="" className="pelumas__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">CV Gelora Putra Jaya</h1>
                    <p className="p pelumas__text p__color">Kosong</p>
                    <button className="btn__cek" onClick={TampilProduk}>
                      Cek Deskripsi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col__2">
                <img src={Fave} alt="" className="keterangan__img" />
              </div>
              <div className="col__2">
                <div className="pelumas__meta">
                  <h1 className="keterangan__pelumas__text">Nama Retail</h1>
                  <p className="p keterangan__pelumas__text p__color">
                    Gelora Group (A World Class Company) adalah sebuah
                    perusahaan besar di Indonesia yang dipimpin oleh Direktur
                    Utama Ibu Laniati Dewi. Grup ini memulai usaha pada bidang
                    Migas, kemudian terus berkembang dan merambah ke berbagai
                    bidang seperti perhotelan, resort, restaurant dll. Beberapa
                    contoh perusahaan yang bergerak di berbagai bidang bisnis
                    seperti layanan distribusi minyak dan gas, SPBU Pertamina,
                    layanan mekanik dan aksesoris mobil, hotel, restoran, ritel,
                    pelumas sepeda motor dan mesin industri dari PT. Pertamina.
                  </p>
                  <button className="btn_stuck pointer" onClick={TampilRetail}>
                    <FontAwesomeIcon icon={faBackward} /> Retail Category
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default RetailCompany;
