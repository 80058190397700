import React, { useContext, useEffect, useState } from "react";
import "../assets/scss/service.scss";
import contact from "../assets/img/contact.png";

function Services() {
  const [showLoker, setShowLoker] = useState(false);
  const [themeLoker, SetShowThemeLoker] = useState("");
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);
  const [jumbotron, setJumbotron] = useState([null]);
  useEffect(() => {
    getJumbotron();
    cektheme();
  }, []);
  const getJumbotron = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/loker/info/`
    );
    let data = await response.json();
    setJumbotron(data);
  };
  const ChangeData = () => {
    setShowLoker(true);
    SetShowThemeLoker("loker__bg");
  };
  return (
    <div className={themecss}>
      <div className="service component__space" id="Service">
        <div className={themeLoker}>
          {/* HOME CONTENT */}
          <div className="home__content">
            {showLoker === false ? (
              <div id="service" className="home__meta">
                <div className="row__cont">
                  <div className="col__2">
                    <div className="contact__box">
                      <div className="contact__meta">
                        <h1 className="service__text white">CAREERS WITH US</h1>
                        <p className="service__text">
                          We always have a variety of job openings. Please do
                          check on the available opportunities below
                        </p>
                        <br></br>
                        <br></br>
                        <p className="service__text">
                          We'll always need exceptional talents to fuel our
                          ambitious dreams.{" "}
                        </p>
                        <br></br>
                        <br></br>
                        <button className="jobs" onClick={ChangeData}>
                          CHECK AVAILABLE JOBS
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="colm__2">
                    <img src={contact} alt="" className="service__img" />
                  </div>
                </div>
              </div>
            ) : (
              <div id="loker" className="home__loker">
                <h1 className="home__text pz__10">INFORMASI LOKER GELORA</h1>
                {jumbotron === null ? (
                  <h2 className="home__text pz__10">Dibutuhkan</h2>
                ) : (
                  <h2 className="home__text pz__10">
                    Dibutuhkan {jumbotron?.judul}
                  </h2>
                )}
                <h3 className="home__text"></h3>
                {jumbotron === null ? (
                  <h4 className="home__text pz__10">Kualifikasi</h4>
                ) : (
                  <h4
                    style={{ whiteSpace: "pre-line" }}
                    className="home__text pz__10"
                  >
                    Kualifikasi : <br></br>
                    {jumbotron?.informasi}
                  </h4>
                )}
                {jumbotron === null ? (
                  <h4 className="home__text pz__10">Catatan</h4>
                ) : (
                  <h4 className="home__text pz__10">{jumbotron?.catatan}</h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
