import React, { useContext, useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import "../assets/scss/partners.scss";
import S2P from "../assets/img/partnership/s2p.png";
import SBI from "../assets/img/partnership/sbi.png";
import Archipelago from "../assets/img/partnership/archipelago.png";
import Indomarco from "../assets/img/partnership/indomarco.png";
import KAI from "../assets/img/partnership/kai.png";
import Quick from "../assets/img/partnership/quick.png";
import PertaminaL from "../assets/img/partnership/pertamina_lubricants.png";
import PertaminaN from "../assets/img/partnership/pertamina_patra.png";
import PertaminaPL from "../assets/img/partnership/pertamina_patral.png";

function Partners() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);
  return (
    <div className={themecss}>
      <div className="partner component__space" id="Blog">
        <div className="heading">
          <h1 className="headingpartners">Our Partners</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={S2P} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://www.ssprimadaya.co.id/about-company.php"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={SBI} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://solusibangunindonesia.com/profil-perusahaan/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={Archipelago} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://www.archipelagointernational.com/en"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={Indomarco} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://id182226-pt-indomarco-adi-prima.contact.page/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={KAI} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://www.kai.id/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={Quick} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://quick.co.id/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={PertaminaL} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="http://pertaminalubricants.com/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={PertaminaN} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://pertaminapatraniaga.com/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>

            <div className="project__box__p pointer relative">
              <div className="project__box__img pointer relative">
                <div className="project__img__box">
                  <img src={PertaminaPL} alt="" className="project__img__p" />
                </div>
                <div className="mask__effect__p"></div>
              </div>
              <div className="Blog__meta__p absolute">
                <a
                  href="https://patralogistik.com/"
                  className="blog project__btn__p btn"
                >
                  See More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
