import Serayumj from "../../assets/img/company/bbm/Serayumj.png";
import "../../assets/scss/allcompany.scss";
import Footer from "../../components/Footer.js";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

function BBMIndustriCompany() {
  const [showDesc, SetShowDesc] = useState(false);
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);

  const TampilDesc = () => {
    SetShowDesc(true);
  };
  const HiddenDesc = () => {
    SetShowDesc(false);
  };

  return (
    <div className={themecss}>
      <Link to={"/"}>
        <button className="backhome">
          <FontAwesomeIcon icon={faBackward} /> Back
        </button>
      </Link>
      <div className="pelumas component__space" id="Pelumas">
        <div className="heading">
          <h1 className="heading">Fuel Division</h1>
          <p className="heading p__color">
            We deliver high quality fuel from Pertamina to industrial customer
            in Indonesia. As authorized fuel distributor, we guaranteed the
            legality and compliance of our product. We provides direct fuel
            bunkering for Tanker Ship via Cilacap Port and land deliveries using
            our fleets of 8KL or 16KL size.
          </p>
        </div>
        {showDesc === false ? (
          <div className="container">
            <div className="row">
              <div className="col__3">
                <div className="pelumas__box pointer">
                  <div className="pelumas__img__box">
                    <img src={Serayumj} alt="" className="project__img" />
                  </div>
                  <div className="pelumas__meta">
                    <h1 className="pelumas__text">
                      PT. Serayu Mitra Jaya Cilacap
                    </h1>
                    <p className="p pelumas__text p__color">
                      Alamat Perusahaan: Jl Laks L RE Martadinata 77,
                      Tambakreja, Cilacap Selatan
                    </p>
                    <button className="btn__cek" onClick={TampilDesc}>
                      Cek Deskripsi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col__2">
                <img src={Serayumj} alt="" className="keterangan__img" />
              </div>
              <div className="col__2">
                <div className="pelumas__meta">
                  <h1 className="keterangan__pelumas__text">
                    PT. Serayu Mitra Jaya Cilacap
                  </h1>
                  <p className="p keterangan__pelumas__text p__color">
                    Gelora Group (A World Class Company) adalah sebuah
                    perusahaan besar di Indonesia yang dipimpin oleh Direktur
                    Utama Ibu Laniati Dewi. Grup ini memulai usaha pada bidang
                    Migas, kemudian terus berkembang dan merambah ke berbagai
                    bidang seperti perhotelan, resort, restaurant dll. Beberapa
                    contoh perusahaan yang bergerak di berbagai bidang bisnis
                    seperti layanan distribusi minyak dan gas, SPBU Pertamina,
                    layanan mekanik dan aksesoris mobil, hotel, restoran, ritel,
                    pelumas sepeda motor dan mesin industri dari PT. Pertamina.
                  </p>
                  <button className="btn_stuck pointer" onClick={HiddenDesc}>
                    <FontAwesomeIcon icon={faBackward} /> Industrial Fuel
                    Category
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default BBMIndustriCompany;
