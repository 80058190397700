import React, { useState } from "react";
import NavbardDashboard from "../components/dashboard/NavbarDashboard"
import SidebarDashboard from "../components/dashboard/SidebarDashboard"
import MainDashboard from "../components/dashboard/MainDashboard"
import "../assets/css/Dashboard.css";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  return (

    <div className="dashboard">
      <NavbardDashboard sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
      <MainDashboard />
      <SidebarDashboard sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
    </div>

  );
};

export default Dashboard;
