import React, { useContext, useEffect, useState } from "react";
import useAxios from "../utils/useAxios";
import "../assets/scss/about.scss";
import aboutImg from "../assets/img/about.png";

function About() {
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();

    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);
  //  Up To Top Btn
  window.addEventListener("scroll", function () {
    const upToTop = document.querySelector("a.bottom__to__top");
    upToTop.classList.toggle("active", window.scrollY > 0);
  });
  return (
    <div className={themecss}>
      <div className="about component__space" id="About">
        <div className="container">
          <div className="row">
            <div className="coll__2">
              <img src={aboutImg} alt="" className="about__img" />
            </div>
            <div className="colll__2">
              <div className="about__meta">
                <h1 className="about__heading">About Us</h1>
                <p className="about__text p__font">
                  Gelora group adalah konsolidasi dari beberapa perusahaan yang
                  sudah berdiri sejak 1955. Berpusat di Cilacap, Jawa Tengah,
                  area bisnis kami meliputi distribusi produk turunan minyak dan
                  gas bumi, produk otomotif dan customer goods, logistic
                  provider serta hospitality.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* UP TO TOP BTN */}
        <div className="up__to__top__btn">
          <a href="#" className="bottom__to__top">
            <svg
              className="chev"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="currentColor"
              class="bi bi-chevron-up white"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
}

export default About;
