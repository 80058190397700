import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import "../assets/scss/home.scss";
import AuthContext from "../context/AuthContext";

const Navbar = ({ children }) => {
  const { user, logoutUser } = useContext(AuthContext);
  const [themecss, setThemeCss] = useState("default");
  const cektheme = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_API_URL}/gelora/theme/`
    );
    let data = await response.json();
    if (data) {
      localStorage.setItem("theme", data.theme);
      setThemeCss(data.theme);
    }
  };
  useEffect(() => {
    cektheme();
  }, []);

  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    header.classList.toggle("active", window.scrollY > 0);
  });
  const [show, setShow] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "#Home",
      name: "Home",
    },
    {
      path: "#About",
      name: "About",
    },
    {
      path: "#Portfolio",
      name: "Division",
    },
    {
      path: "#Contact",
      name: "Contact",
    },
    {
      path: "#Service",
      name: "Jobs",
    },
    {
      path: "#Blog",
      name: "Partners",
    },
  ];
  return (
    <div className={themecss}>
      <div className="navbar__bg">
        <div className="header align__items__center">
          <div className="navigation">
            <ul className="navbar d__flex">
              <a href="#Home">
                <li className="nav__items mx__15 white">Home</li>
              </a>
              <a href="#About">
                <li className="nav__items mx__15 white">About</li>
              </a>
              <a href="#Portfolio">
                <li className="nav__items mx__15 white">Division</li>
              </a>
              <a href="#Contact">
                <li className="nav__items mx__15 white">Contact</li>
              </a>
              <a href="#Service">
                <li className="nav__items mx__15 white">Jobs</li>
              </a>
              <a href="#Blog">
                <li className="nav__items mx__15 white">Partners</li>
              </a>
            </ul>
          </div>
          {/* Toogle Menu */}
          {show ? (
            <div
              className="sideNavbar"
              style={{
                paddingBottom: isOpen ? "20px" : "0px",
              }}
            >
              <div
                style={{
                  width: isOpen ? "20px" : "20px",
                  fontSize: 20,
                }}
                className="sidebar"
              >
                <div className="top_section" style={{ marginBottom: 30 }}>
                  <div
                    style={{
                      marginLeft: isOpen ? "50px" : "0px",
                      color: "#FFFFFF",
                    }}
                    className="bars toggle__menu"
                  >
                    <FaBars onClick={toggle} />
                  </div>
                </div>
                {menuItem.map((item, index) => (
                  <NavLink
                    to={item.path}
                    key={index}
                    className="link"
                    activeclassName="active"
                  >
                    <div
                      style={{
                        display: isOpen ? "block" : "none",
                        color: "#FFFFFF",
                        paddingTop: 20,
                        fontSize: 17,
                      }}
                      className="link_text"
                    >
                      {item.name}
                    </div>
                  </NavLink>
                ))}
              </div>
              <main>{children}</main>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
